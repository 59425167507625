import { useLocalStorage, watchImmediate } from '@vueuse/core';
import { computed, ComputedRef } from 'vue';
import { useI18n } from 'vue-i18n';

import { ListEntry } from '@silae/helpers';
import { updateLuxonConfiguration, updateVeeValidateConfig } from '~/plugins';
import { getStorageKey } from '~/utils';

const availableLocales: Array<ListEntry<string>> = [
	{ label: 'English', value: 'en' },
	{ label: 'Français', value: 'fr' }
];

export function useLocale(): {
	locale: ComputedRef<string>;
	locales: Array<ListEntry<string>>;
	setLocale: (locale: string) => void;
} {
	// store preferred locale to storage
	const _locale = useLocalStorage(getStorageKey('preferred-locale'), 'fr');
	const setLocale = (newLocale: string) => {
		if (availableLocales.findIndex(it => it.value === newLocale) < 0) return;
		_locale.value = newLocale;
	};

	const { locale: _i18nLocale } = useI18n({ useScope: 'global', locale: _locale.value });

	// synchronize locale in sub systems
	watchImmediate(_locale, locale => {
		_i18nLocale.value = locale;
		updateLuxonConfiguration({ locale });
		updateVeeValidateConfig({ locale });
	});

	return {
		locale: computed(() => _locale.value),
		locales: availableLocales,
		setLocale
	};
}
