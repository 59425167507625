import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { defaultLayoutComponents } from '~/pages/layout';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

export const StatisticsRoutes: RouteRecordRaw = {
	path: '/requirements',
	name: RouteKeys.DOC_TABLE,
	components: {
		default: () => import('./StatisticsView.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isPM } = storeToRefs(useRolesStore());
			return computed(() => !isPM.value);
		},
		label: 'statistics.navigation',
		isSubEntry: true,
		order: 41
	}
};

export const AnalysisItemsRoutes: RouteRecordRaw = {
	path: '/doc/table/analysis-items',
	name: RouteKeys.DOC_TABLE_ANALYSIS_ITEMS,
	components: {
		default: () => import('./RulesView.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isPM } = storeToRefs(useRolesStore());
			return computed(() => !isPM.value);
		}
	}
};

export const docRoutes: Array<RouteRecordRaw> = [
	{
		path: '/doc',
		name: RouteKeys.DOC,
		components: {
			default: () => import('./NotFoundView.vue'),
			...defaultLayoutComponents
		},
		meta: { menuWidth: 450, navBar: true, sideMenu: false, requiresAuth: true }
	},
	{
		path: '/doc/folder/:id',
		name: RouteKeys.DOC_FOLDER,
		components: {
			default: () => import('./FolderView.vue'),
			...defaultLayoutComponents
		},
		meta: { menuWidth: 450, navBar: true, sideMenu: false, requiresAuth: true }
	},
	{
		path: '/doc/test-case/:id',
		name: RouteKeys.DOC_TEST_CASE,
		components: {
			default: () => import('./TestCaseView.vue'),
			...defaultLayoutComponents
		},
		meta: { menuWidth: 450, navBar: true, sideMenu: false, requiresAuth: true }
	},
	{
		path: '/doc/:pathMatch(.*)*',
		name: RouteKeys.DOC_NOT_FOUND,
		components: {
			default: () => import('./NotFoundView.vue'),
			...defaultLayoutComponents
		},
		meta: { menuWidth: 450, navBar: true, sideMenu: false, requiresAuth: true }
	},
	StatisticsRoutes
];
