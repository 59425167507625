import { randomCCN } from '~/data/ccn';
import { fakeSiren } from '~/types/dsn.faker';
import { randomString, sizedNumber, toDate } from '~/utils';

export type ModuleType = 'C&A' | 'EVP' | 'Entretiens';
export const moduleTypes: ModuleType[] = ['C&A', 'EVP', 'Entretiens'];

export class Company {
	id = 1;
	folderNumber: string;
	folderNumberRef: string | undefined;
	dateMontage: Date;
	generateAbsencesType: number;
	name: string;
	siren: string | undefined;
	emailAlias: string | undefined;
	emailBase: string | undefined;
	lastContractNum: number;
	ccnCode: string;
	ccnName: string;
	CPAcqMois: number;
	generated: boolean;
	archive: boolean;
	generationContext?: string;
	adminId?: number;
	modules: ModuleType[] = [];

	constructor(siren: string = fakeSiren()) {
		const date = new Date();
		this.generated = false;
		this.name = '';
		let dateText = date.getFullYear().toFixed().substring(2, 4);
		dateText += sizedNumber(date.getMonth() + 1, 2);
		dateText += sizedNumber(date.getDate(), 2);
		this.emailBase = '@silae.fr';
		this.folderNumber = `${dateText}_DO1`;
		this.generateAbsencesType = 4;
		this.lastContractNum = 1;
		this.dateMontage = new Date();
		this.CPAcqMois = 2;

		const ccn = randomCCN();
		this.ccnCode = ccn.code;
		this.ccnName = ccn.name;
		this.siren = siren;
	}
}

export function getStartDateString(company: Company): string {
	const date: Date = toDate(company.dateMontage);
	return `${date.getFullYear()}-${sizedNumber(date.getMonth() + 1, 2)}-01T00:00:00`;
}

/**
 * find unused folder number
 * @param company
 * @param folderList
 */
export function checkFolderNumber(company: Company, folderList: string[]): string {
	const date = new Date();
	let dateText = date.getFullYear().toFixed().substring(2, 4);
	dateText += sizedNumber(date.getMonth() + 1, 2);
	dateText += sizedNumber(date.getDate(), 2);
	let num = `${dateText}_DO1`;
	if (folderList.length == 0) {
		company.folderNumber = num;
		return num;
	}
	while (folderList.includes(num)) {
		num = `${dateText}_${randomString(3).toUpperCase()}`;
	}
	company.folderNumber = num;
	return num;
}
