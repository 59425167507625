import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Observable } from 'rxjs';


/**
 * @type HttpService
 */
export type HttpService = {
	delete<T = any>(url: string, data?: any, config?: AxiosRequestConfig, responseMapper?: (res: AxiosResponse) => any): Observable<T>;
	get<T = any>(url: string, config?: AxiosRequestConfig, responseMapper?: (res: AxiosResponse) => any): Observable<T>;
	getPage: <T>(url: string, page: number, size: number) => Observable<PaginationData<T>>;
	patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig, responseMapper?: (res: AxiosResponse) => any): Observable<T>;
	post<T = any>(url: string, data?: any, config?: AxiosRequestConfig, responseMapper?: (res: AxiosResponse) => any): Observable<T>;
	put<T = any>(url: string, data?: any, config?: AxiosRequestConfig, responseMapper?: (res: AxiosResponse) => any): Observable<T>;
};

/**
 * @enum RequestMethod
 */
export enum RequestMethod {
	GET = 'get',
	HEAD = 'head',
	POST = 'post',
	PUT = 'put',
	PATCH = 'patch',
	DELETE = 'delete',
	OPTIONS = 'options',
	TRACE = 'trace'
}

export class PaginationRequest {
	page: number;
	size: number;

	constructor(page: number = 0, size: number = 20) {
		this.page = page;
		this.size = size;
	}
}

export class PaginationData<T> {
	totalElements: number;
	totalPages: number;
	size: number;
	number: number;
	content: Array<T>;

	constructor(data: Array<T>, page: number, size: number, count: number, pageCount: number) {
		this.content = data;
		this.totalElements = count;
		this.totalPages = pageCount;
		this.size = size;
		this.number = page;
	}
}

export function buildPaginationQueryOpts(page: number = 0, size: number = 20) {
	const paginationQuery = new PaginationRequest(page, size);
	return buildQueryOpts(paginationQuery);
}

export function buildQueryOpts(opts: any) {
	return Object.entries(opts)
		.map(([paramName, paramValue]) => {
			if (Array.isArray(paramValue)) {
				return paramValue.map(eachValue => `${paramName}=${eachValue}`).join('&');
			}
			return `${paramName}=${paramValue}`;
		})
		.join('&');
}
