export const RouteKeys = {
	ACCOUNT: 'account',
	ACCOUNT_SECURITY: 'account.security',
	CONNECTION: 'connection',
	HOME: 'home',
	NOT_FOUND: '404',
	CONFIG: 'config',
	CONFIG_PFS: 'config-pfs',
	CONFIG_USER: 'config-users',
	CONFIG_USER_GROUPS: 'config-user-groups',
	CONFIG_USER_GROUPS_EDIT: 'config-user-groups-edit',
	CONFIG_PASSWORD: 'config-password',
	METRIC: 'metric',
	METRIC_TASK: 'metric-task',
	METRIC_FODLER: 'metric-folder',
	PAY_GEN: 'pay-gen-home',
	PAY_GEN_ESTABLISHMENT: 'pay-gen-establishment',
	PAY_GEN_EMPLOYEE: 'pay-gen-employee',
	PAY_GEN_TEAM: 'pay-gen-team',
	PAY_GEN_MULTI: 'pay-gen-multi',
	PAY_GEN_SINGLE: 'pay-gen',
	PAY_GEN_SINGLE_COMPANY_ID: 'pay-gen-company-id',
	SIGN_IN: 'sign-in',
	QA2: 'QA2',
	PM_HOME: 'PM_HOME',
	QA2_CONFIG: 'QA2-config',
	QA2_CONFIG_PATTERN: 'QA2-config-pattern',
	QA2_TEST_CASE: 'QA2test-case',
	QA_AUTO_HOME: 'qa-auto-home',
	QA_AUTO_CRON: 'qa-auto-cron',
	QA_AUTO_FOLDER_ERROR: 'qa-auto-folder-error',
	RELEASE_NOTE: 'release-note',
	DOC: 'doc',
	DOC_TABLE: 'doc-table',
	DOC_TABLE_ANALYSIS_ITEMS: 'doc-table-analysis-items',
	DOC_FOLDER: 'doc-folder',
	DOC_REQUIREMENT: 'doc-requirement',
	DOC_REQUIREMENT_RULES: 'doc-requirement-rules',
	DOC_TEST_CASE: 'doc-test-case',
	DOC_NOT_FOUND: 'doc-not-found',
	UPDATE_PASSWORD: 'update-password',
	UPDATE_PASSWORD_GENERATE_CODE: 'update-password.generate-code',
	UPDATE_PASSWORD_RESET: 'update-password.reset',
	UPDATE_PASSWORD_RESET_NO_PARAMETER: 'update-password.reset.np',
	SUPPORT: 'support'
};
