import { RouteRecordRaw } from 'vue-router';

import { AccountRoute } from '~/pages/account/account.route';
import { ConfigRoute } from '~/pages/config/config-routes';
import { PayGenRoute } from '~/pages/paiegen/pay.gen-routes';
import { ConnectionRoute, SignInRoute } from '~/pages/sign-in/sign-in.route';
import { AnalysisItemsRoutes } from '~/pages/pm/statistics/statistics-routes';
import { QA_HOME_ROUTE } from '~/pages/testcase/qa2-routes';
import { RouteKeys } from '~/router/routes.domain';
import { MetricRoute } from '~/pages/metric/metric-routes';
import { HomeRoute } from '~/pages/home/home-routes';
import { requirementFromRulesRoutes, requirementRoutes } from '~/pages/pm/requirement/requirement-routes';
import { QaConfigPatternRoute, QaConfigRoute } from '~/pages/testcase/config/qa.config.route';
import { SupportRoutes } from '~/pages/support/support-routes';
import { qaAutoRoutes } from '~/pages/qaauto/qa-auto-routes';
import { PMRoute } from '~/pages/pm/pm-routes';

const FallbackRoute: RouteRecordRaw = {
	name: RouteKeys.NOT_FOUND,
	path: '/:pathMatch(.*)*',
	redirect: HomeRoute,
	meta: {
		requiresAuth: true
	}
};

export const routes = [
	HomeRoute,
	PayGenRoute,
	PMRoute,
	AnalysisItemsRoutes,
	QA_HOME_ROUTE,
	...qaAutoRoutes,
	QaConfigRoute,
	QaConfigPatternRoute,
	ConnectionRoute,
	requirementRoutes,
	requirementFromRulesRoutes,
	SignInRoute,
	ConfigRoute,
	MetricRoute,
	AccountRoute,
	SupportRoutes,
	FallbackRoute
];
